<template>
	<div class="px-3 py-3 text-left md:px-0 md:justify-self-center">
		<div class="flex justify-between w-full py-2 font-bold md:text-lg">
			{{ title }}
		</div>

		<div class="text-base text-gray-500">
			<ul
				v-for="link in links"
				:key="link.name"
			>
				<li class="py-1">
					<router-link
						class="hover:text-green-500"
						:to="{ name: link.link }"
					>
						{{ link.name }}
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script defer>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		links: {
			type: Array,
			required: true
		}
	}
}
</script>
